import React, { useEffect, useState, useRef } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import SearchBar from '../components/Commons/SearchBar.js';

const io = require("socket.io-client")



export default function Chat() {
	let input = "";
	const [count, setCount] = useState(0);
	const item = useRef([]);
	const email = useRef(localStorage.getItem("email"));			//persist the socketId
	const userID = useRef(localStorage.getItem("id"));	
	const socketId = useRef([])										
	const socketInit = useRef(io("http://localhost:3001"));			//const [socket, setSocket] = useState(io("https://arcane-taiga-71197.herokuapp.com/"))
	const socket = socketInit.current;
	const [searchResult, setSearchResult] = useState();
	const [conversation, setConversation] = useState();

	const send = function(e) {
		console.log("sent clicked")
		e.preventDefault();
	    if (input.value) {
	      socket.emit('chat message', input.value);
	      input.value = '';
	      console.log("sent clicked")
	  }
	}
	
	useEffect(() => {
		input = document.getElementById("input")
		socket.on("connect", () => {
			socket.emit("user", localStorage.getItem("email"))
			socketId.current.push(socket.id);

		})
		return () => {
			socket.disconnect();
		}
	}, [])

	socket.once('chat message', (msg) => {
			console.log("received")
			console.log(msg)
			item.current.push(<h5 key={count}>{msg}</h5>)	
			console.log(item.current);
			setCount(count + 1);
	})


	return(
		<div className="d-flex noNavHeight p-3">
			<ChatList />
			<ChatRoom />
		</div>
	)



	function ChatList() {									// to replace fetch
																	
		return (
			<div className="col-3 offset-2 bg-1 p-4" style={{borderRadius: "15px 0px 0px 15px"}}>
				<h3 className="color-5">Chats</h3>
				<hr className="mt-0 color-5"/>
				<SearchBar fetchURL={"http://localhost:3001/chat/search?fullName=toReplace"} setResult={setSearchResult}/>
				<SearchList />
			</div>
		)
	}

	function ChatRoom () {
		return(
			<div className="col-5 d-flex flex-column justify-content-between bg-5" style={{borderRadius: "0px 15px 15px 0px"}}>
				
				<div className="bg-5 h-100" style={{borderRadius: "0px 15px 0px 0px"}}>
					<div children={item.current.map(x => x)} />
				</div>
				<div className="">
					<TypeArea/>
				</div>
			</div>
		)
	}

	function TypeArea () {
		return (
		<div className="m-3 p-3">
			<Form>
				<div className="d-flex">
					<div className="col-10">
						<Form.Group>
							<Form.Control id="input" as="textarea" rows={1} />
						</Form.Group>
					</div>
					<div className="col-2 ms-3">
						<Button onClick={send} className="w-100">Send</Button>
					</div>
				</div>
			</Form>	
		</div>

		//
			
		)
	}

	function SearchList() {
		if (!searchResult || searchResult.error)
			return;

		let list = searchResult.map((x) => {
			return(
				<Row className="my-2" onClick={e => handleConversationClick(e, x._id)}>
					<div className="col-2 pe-0 m-0">
						<img src="https://www.pngitem.com/pimgs/m/508-5087336_person-man-user-account-profile-employee-profile-template.png" style={{ width: "40px", borderRadius: "50%" }}/>
					</div>
					<div className="col-10 m-0 p-0">
						<h5 className="m-0 p-0 default">{x.fullName}</h5>
					</div>
				</Row>
			)
		})
		

		function handleConversationClick(e, id) {
			e.preventDefault();
			let id1, id2;
			if (userID > id) {
				id1 = userID;
				id2 = id;
			}
			else {
				id1 = id;
				id2 = userID;
			}

			fetch(`http://localhost:3001/chat/room?id1=${id1}&id2=${id2}`).then(response => response.json()).then(ok => {
				console.log(ok)
			})
		}

		return (
			<div className="mt-3">
				{list}
			</div>
		)
	}
}