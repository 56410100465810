module.exports.questionGenerator = (questions) => {
	let convFact = [{
		aName: "inch",
		a: 1,
		bName: "cm",
		b: 2.54,
		metric: false
	},
	{
		aName: "mm",
		a: 1,
		bName: "cm",
		b: 0.1,
		metric: true
	},
	{
		aName: "cm",
		a: 1,
		bName: "dm",
		b: 0.1,
		metric: true
	},
	{
		aName: "dm",
		a: 1,
		bName: "m",
		b: 0.1,
		metric: true
	},
	{
		aName: "m",
		a: 1000,
		bName: "km",
		b: 1,
		metric: true
	},
	{
		aName: "km",
		a: 1,
		bName: "mi",
		b: 0.621371,
		metric: false
	},
	{
		aName: "ft",
		a: 1,
		bName: "in",
		b: 12,
		metric: false
	},
	{
		aName: "yrd",
		a: 1,
		bName: "ft",
		b: 3,
		metric: false
	},
	{
		aName: "mm",
		a: 1000,
		bName: "m",
		b: 1,
		metric: true
	},
	{
		aName: "g",
		a: 1,
		bName: "mg",
		b: 1000,
		metric: true
	},
	{
		aName: "g",
		a: 1000,
		bName: "kg",
		b: 1,
		metric: true
	},
	{
		aName: "mg",
		a: 1000000,
		bName: "kg",
		b: 1,
		metric: true
	},
	{
		aName: "sec",
		a: 60,
		bName: "min",
		b: 1,
		metric: false
	},
	{
		aName: "sec",
		a: 3600,
		bName: "hr",
		b: 1,
		metric: false
	},
		{
		aName: "min",
		a: 60,
		bName: "hr",
		b: 1,
		metric: false
	}]


	let x = convFact.length

	for (let i = 0; i < 15; i++) {
		let qString = "Convert";
		let y = Math.floor(Math.random() * x);
		let a = convFact[y].a;
		let aName = convFact[y].aName;
		let b = convFact[y].b;
		let bName = convFact[y].bName;
		let value = Math.random() * 100;
		value = value.toFixed(2);
		let item = {};

		if(Math.floor(Math.random() * 2) == 0) {
			qString += " " + value + " " + aName + " to " + bName + ".";
			item['q'] = qString;
			let temp = (value * b / a)
			if(temp.toFixed(3) == "0.000") 
				item['c'] = `${temp} ${bName}`
			else
				item['c'] = `${temp.toFixed(3)} ${bName}`
			if (convFact[y].metric === true) {
				console.log("metric")
				item['w1'] = generateWrongMetric(value, bName, item['c'])
				item['w2'] = generateWrongMetric(value, bName, item['c'], item['w1'])
				item['w3'] = generateWrongMetric(value, bName, item['c'], item['w1'], item['w2'])
			}
			else {
				item['w1'] = generateWrong(b, bName);
				item['w2'] = generateWrong(b, bName);
				item['w3'] = generateWrong(b, bName);
			}
			
		}
		else {
			qString += " " + value + " " + bName + " to " + aName;
			item['q'] = qString;
			let temp = (value * a / b)
			if(temp.toFixed(3) == "0.000") 
				item['c'] = `${temp} ${aName}`
			else
				item['c'] = `${temp.toFixed(3)} ${aName}`
			if (convFact[y].metric === true) {
				console.log("metric")
				item['w1'] = generateWrongMetric(value, aName, item['c'])
				item['w2'] = generateWrongMetric(value, aName, item['c'], item['w1'])
				item['w3'] = generateWrongMetric(value, aName, item['c'], item['w1'], item['w2'])
			}
			else {
				item['w1'] = generateWrong(a, aName);
				item['w2'] = generateWrong(a, aName);
				item['w3'] = generateWrong(a, aName);
			}
			
		}
		if (item['c'] == 0 ) {
			i--;
			continue;
		}
		else {
			questions.push(item); 
		}
	}
}

function generateWrong(x, xName) {
	return `${(Math.random() * 80 * x).toFixed(3)} ${xName}`;
}

function generateWrongMetric(x, xName, avoid, avoid2, avoid3) {
	let question;
	let valid = false;

	while(valid == false) {
		if (Math.random() * 2 < 1) {
			question = `${(x * Math.pow(10, Math.floor(Math.random() * 4))).toFixed(3)} ${xName}`
		}
		else {
			console.log("smaller")
			question = `${(x / Math.pow(10, Math.floor(Math.random() * 5))).toFixed(3)} ${xName}`
		}
		if (question != avoid && question != avoid2 && question != avoid3) {
			valid = true;
		}
	}
	return question;
}