import React, {useEffect, useState} from 'react';
import pic1 from '../pictures/student4.jpg'
import {scroller} from 'react-scroll';
import { Form, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import emailValidator from '../components/Commons/Validators/emailValidator';
import passwordChecker from '../components/Commons/Validators/passwordChecker';
import phoneValidator from '../components/Commons/Validators/phoneValidator';
import bdayValidator from '../components/Commons/Validators/bdayValidator';
import Swal from 'sweetalert2';


export default function Register() {
	const [fName, setFName] = useState("");
	const [mName, setMName] = useState("");
	const [lName, setLName] = useState("");
	const [email, setEmail] = useState("");
	const [pass, setPass] = useState("");
	const [vPass, setVPass] = useState("");
	const [phone, setPhone] = useState("");
	const [bday, setBday] = useState();	
	const [next, setNext] = useState(() => <Button className="bg-2" disabled>Next</Button>)
	const [prev, setPrev] = useState(() => <Button className="bg-2" disabled>Previous</Button>)
	const [passResult, setPassResult] = useState("");
	let [emailResult, setEmailResult] = useState("");
	const [phoneResult, setPhoneResult] = useState("");
	const [showModal, setShowModal] = useState(false);
	const navi = useNavigate();

 	useEffect(() => {
 		scrollToSection();
 		if (localStorage.getItem("token") && new Date(localStorage.getItem("expiry")) > new Date())
		navi("/")
 	}, [])

 	useEffect(() => {
 		setPassResult(passwordChecker (pass, vPass, fName, lName))
 		setEmailResult(emailValidator(email))
 		setPhoneResult(phoneValidator(phone))

 	}, [pass, vPass, email, phone])
	return(
		<div className="registerDiv d-flex p-0 m-0 fullWindowWidth" id="registration">
			<img src={pic1} alt="" id="loginPic" className="d-none d-lg-block m-0 py-5 ps-5 pe-0"/>
			<div id="loginCard" className=" d-flex flex-column  container-fluid color-2 border-bottom border-right p-1 me-lg-5 pe-lg-5 ms-lg-4 my-lg-5 pb-lg-5 ">
				<h1 className="p-2 p-lg-0 ps-lg-3 m-lg-3 mb-lg-4 text-res1 varela color-1">Register</h1>
				
				<Form className="p-2 ms-lg-3 ps-lg-3 mb-lg-5 color-1">
					{Page1()}
				</Form>
			</div>
		</div>
	);
//function declarations
	function Page1() {
		return (
		<>
			<div className="row">
				<Form.Group className="mb-3 col-5 pe-2">
					<Form.Control type="text" placeholder="First Name: " onChange={e => setFName(e.target.value)} value={fName} required/>		
				</Form.Group>

				<Form.Group className="mb-3 col-2 px-0">
					<Form.Control type="text" placeholder="MI: " maxLength="3" onChange={e => setMName(e.target.value)} value={mName} />
				</Form.Group>

				<Form.Group className="mb-3 col-5 ps-2">
					<Form.Control type="text" placeholder="Last Name: " onChange={e => setLName(e.target.value)} value={lName} required/>
				</Form.Group>
			</div>

			<Form.Group className="mb-2 ">
				<Form.Control type="email" placeholder="Email: " onChange={e => setEmail(e.target.value)} value={email} required/>
				<div className="d-flex justify-content-between">
				
				{
					(emailResult.message && email) ?
					<Form.Text className="text-danger m-0 ms-1 p-0">{emailResult.message[0]}</Form.Text>:
					<></>
				}
				</div>
			</Form.Group>	

			<Form.Group className="mt-3"	>
				<Form.Control type="password" placeholder="Password: " onChange={e => setPass(e.target.value)} value={pass} required/>
				{

					(passResult.message) ?
					<Form.Text className="text-danger">{passResult.message[0]}</Form.Text>:
					<></>

				}
			</Form.Group>	

			<Form.Group className="mt-3"	>
				<Form.Control type="password" placeholder="Verify Password: " onChange={e => setVPass(e.target.value)} value={vPass} required/>
			</Form.Group>
			
			<div className="row">
				<Form.Group className="mt-3 pe-1 col-7">
					<Form.Control type="text" placeholder="Phone Number: " onChange={e => setPhone(e.target.value)} value={phone} required/>
				</Form.Group>	

				<Form.Group className="mt-3 ps-1  col-5">
					<Form.Control type="date" placeholder="Date of Birth: " onChange={e => setBday(e.target.value)} value={bday} required/>
				</Form.Group>	
			</div>

			{
				(phoneResult.message) ?
				<Form.Text className="text-danger">{phoneResult.message}</Form.Text>:
				<></>


			}

			<Form.Group className="mb-3 mt-2">
				<Link to="/login" className="color-1" >Already have an account? Click here!</Link>
			</Form.Group>	
			
			{
				(fName.length > 0 && mName.length > 0 && lName.length > 0 && emailResult.bool == true && pass.length >= 8 && phone.length == 11 &&passResult.bool == true && phoneResult.bool == true && bday ) ?
				<Button className="bg-2 container-fluid" onClick={sendData}>Register</Button> :
		 		<Button className="bg-2 container-fluid" disabled>Register</Button>
		 	}

		</>
		)
	}

	function sendData() {
		fetch(`https://arcane-taiga-71197.herokuapp.com/users/register`,
		{
			"method": "POST",
			"headers": {"Content-Type": "application/json"},
			"body": JSON.stringify({
				fName: fName,
				mName: mName,
				lName: lName,
				pass: pass,
				vPass: vPass,
				email: email,
				phone: phone,
				bday: bday
			})
		}).then(response => response.json()).then(data => {
			if(data.message) {
				Swal.fire({
					title: "Account Registered Successully!",
					text: "Login to your account to start exploring now!",
					icon: "success"
				})

				setFName("")
				setMName("")
				setLName("")
				setPass("")
				setVPass("")
				setEmail("")
				setPhone("")
				setBday("")
				navi("/login")
				
			}
			else
				Swal.fire({
					title: "Error",
					text: data.error,
					icon: "error"
				})
		})
	}


}

function scrollToSection() {
    scroller.scrollTo("registerDiv", {
    });
};

function sendLoginData() {

}
