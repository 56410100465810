import React, { useState, useRef, useEffect } from 'react';
import { Card, Form, Button, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

export default function Quiz() {
	let [score, setScore] = useState(0);
	let [page, setPage] = useState(1);
	let [questions, setQuestions] = useState([])
	let [answers, setAnswers] = useState([])
	let [init, setInit] = useState(0);
	let [refresh, setRefresh] = useState(0)
	let [questionForm, setQuestionForm] = useState([]);
	let [correct, setCorrect] = useState([])
	let [choicesKey, setChoicesKey] = useState([]);
	let [finished, setFinished] = useState(false)
	const params = useParams();
	let a = require(`../components/Quiz/${params['quiz']}.js`);
	
	if (questions.length == 0) {
		a.questionGenerator(questions);
	}
	
	const handleSubmit = (e) => {
		let temp = 0;
		for (let i = 0 ; i < 15 ; i++) {
			console.log(`Answer is ${answers[i]} and the correct is ${correct[i]}`)
			temp = (answers[i] == "correct") ? temp + 1 : temp;

		}
		console.log(temp)
		setScore(temp)
		setFinished(true)
	}

	const handleNext = (e) => {
		setPage(page+1)
	}
	const handleBack = (e) => {
		setPage(page-1)	
	}
	
	useEffect(() => {
		setRefresh(init)
	}, [init])

	let index = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]; //for shuffling

	questionForm.length = 0;

 		for (let i = 0; i < 15; i++) {

			if(choicesKey.length <= 14) {
				let choiceShuffle = [0, 1, 2, 3];
				choiceShuffle.sort(function() { return Math.random() - 0.5 })
				choicesKey[i] = choiceShuffle
			}
			let choices = []

			for (let j = 0; j < 4; j++) {
				let x = choicesKey[i][j];


				/*select functions*/
					function wrongSelect1(e) {
						e.preventDefault();
						answers[i] = "wrong1"

						setInit(init + 1)
					}

					function wrongSelect2(e) {
						e.preventDefault();
						answers[i] = "wrong2"

						setInit(init + 1)
					}

					function wrongSelect3(e) {
						e.preventDefault();
						answers[i] = "wrong3"

						setInit(init + 1)
					}
					function correctSelect(e) {
						e.preventDefault()
						answers[i] = "correct"

						setInit(init + 1)	
					}

				switch(x) {
					case 0:

						choices.push(<Form.Check label={questions[i].w1} type="radio" value="wrong1" onChange={wrongSelect1} checked={answers[i] == 'wrong1'}/>);
						break;
					case 1:

						choices.push(<Form.Check label={questions[i].w2} type="radio" value="wrong2" onChange={wrongSelect2} checked={answers[i] == 'wrong2'}/>);
						break;
					case 2:

						choices.push(<Form.Check label={questions[i].w3} type="radio" value="wrong3" onChange={wrongSelect3} checked={answers[i] == 'wrong3'}/>);
						break;
					case 3:

						choices.push(<Form.Check label={questions[i].c} type="radio" value="correct" onChange={correctSelect} checked={answers[i] == 'correct'}/>);
						correct.push(j);
						break;	
				}
			}
			let s1 = <Form.Group>{choices}</Form.Group>
			questionForm.push(s1)
		}

	function QuestionCards() {
		return(
		<Card>
				<Card.Header>Question {page} out of 15</Card.Header>
				
				<Card.Body>
				<p className="">{questions[page-1].q}</p>
					<Form>
						{questionForm[page-1]}
					</Form>
					<Row className="mx-1 mt-3">
						<div className="col-4 p-1">
							{(page > 1) ? <Button onClick={handleBack} className="container-fluid p-0 m-0">Back</Button> : <></> }
						</div>

						<div className="col-4 p-1">
							<Button onClick={handleSubmit} className="container-fluid p-0 m-0">Submit</Button>
						</div>

						<div className="col-4 p-1">
							{(page < 15)? <Button onClick={handleNext} className="container-fluid p-0 m-0">Next</Button> : <></> }
						</div>
					</Row>
				</Card.Body>	
			</Card>	)
	}

	function FinishedQuiz() {
		return (
			<>
				<h1>You've Finished!</h1>
				<hr />
				<h5>You scored: {score} out of 15</h5>
				{(score <= 5) ? <p>Hehe na lang sa'yo</p> : <></>}
			</>
		)
	}


	return(
		<div className="p-3">
		
			{ (finished) ? <FinishedQuiz /> : <QuestionCards />
			}
		
		</div>
	);

	
}