import React, {useState} from 'react';
import { InputGroup, Form, Button } from 'react-bootstrap';

export default function SearchBar({fetchURL, setResult}) {
	const [search, setSearch] = useState("");
	
	let handleSearch = (e) => {
		setSearch(e.target.value);
	}

	fetchURL = fetchURL.replaceAll("toReplace", search)
	console.log(fetchURL)
	let fetcher = () => {
		fetch(fetchURL, {
			"method": "GET",
			"headers": {"Content-Type": "application/json"},
			
		}).then(response => response.json()).then(ok => {
			console.log(ok);
			setResult(ok);
		})
	}
	return(
		<InputGroup>
			<Form.Control placeholder="Search" onChange={handleSearch} value={search}/>
			<Button className="h-25 w-25" onClick={fetcher}><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/0b/Search_Icon.svg/1024px-Search_Icon.svg.png" className="h-25 w-50" /> </Button>
		</InputGroup>
	)
}