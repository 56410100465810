import {Navbar, Nav, Container, NavDropdown} from 'react-bootstrap';
import logo from "../../pictures/logo_white.png";
import {Link} from "react-router-dom";
import { useContext } from 'react';
import UserContext from '../../contexts/UserContext.js';
export default function AppNavbar() {

	const { user } = useContext(UserContext);
	let login = user.token && user.email && new Date(user.expiry) > new Date();
	let signed = (login) ? 
	<>
		<Link to="/myAccount" className="navLink Poppins mb-1 mx-md-1 mt-1 p-0">My Account</Link>
		<Link to="/logout" className="navLink Poppins mb-1 mx-md-1 mt-1 p-0">Logout</Link>
	</>
		:
	<>
		<Link to="/login" className="navLink Poppins mb-1 mx-md-1 mt-1 p-0">Log In</Link>
		<Link to="/register" className="navLink Poppins mb-1 mx-md-1 mt-1 p-0">Register</Link>
	</>;

	return (
	<Navbar expand="lg" className=" mt-0 p-0 pt-0 w-100 bg-1" >
	  <Container fluid className="px-0 mx-1 mx-lg-5">
	    <Navbar.Brand href="/" className="m-0">
	    	<img src={logo} alt="logo" className="logo m-0 p-0" />
	    </Navbar.Brand>
	    <Navbar.Toggle aria-controls="basic-navbar-nav" className="navbar-dark"/>
	    <Navbar.Collapse id="basic-navbar-nav" >
	    	<div className="d-flex flex-column flex-lg-row container-fluid justify-content-lg-between">
		      <Nav>
		        <Link to="/" className="navLink Poppins mb-1 mx-md-1 mt-1 p-0">Home</Link>
		        <Link to="/bio" className="navLink Poppins mb-1 mx-md-1 mt-1 p-0">Biology</Link>
		        <Link to="/chem" className="navLink Poppins mb-1 mx-md-1 mt-1 p-0">Chemistry</Link>
		        <Link to="/phys" className="navLink Poppins mb-1 mx-md-1 mt-1 p-0">Physics</Link>
		        <Link to="/review" className="navLink Poppins mb-1 mx-md-1 mt-1 p-0">Review</Link>
		       
		      </Nav>
		      <Nav className="">{signed}</Nav>
	     	</div>
	    </Navbar.Collapse>
	    
	  </Container>

	</Navbar>
	)
}