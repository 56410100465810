import React, {useContext, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../contexts/UserContext.js'
export default function Logout() {
	const navi = useNavigate();
	const {setUser} = useContext(UserContext)
	setUser({})
	useEffect(() => {
		navi("/")
	})
	localStorage.clear();
		return(
		<>

		</>
	);
}