import React, {useEffect,useState} from 'react';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';


export default function ForumHome() {


	return(
		<Row className="mt-4 mx-0 px-5">
			<Col className="col-4 ">hello</Col>
			<ForumTable name="Teacher" number="8" />
		</Row>
	)
}

function ForumTable({name, number}) {
	const [render, setRender] = useState(0);
	const [forumCards, setForumCards] = useState([])
	useEffect(() => {
		fetch(`http://localhost:3001/forums/forumhome-${name}-${number}`).then(response => response.json()).then(ok => {	//https://arcane-taiga-71197.herokuapp.com/forums/forumhome-${name}-${number}
			ok.forEach(x => {
				console.log(x)
				let date = new Date(x.lastModified).toUTCString()	;
				forumCards.push(
					<div className="my-2 all-border-2 bg-5 px-3 py-2 border-radius-1">
						<h5 className="pb-1 mb-1 fs-16">{x.title}</h5>
						<hr className="py-0 my-1"/>
						<div className="d-flex">
							<div>
							<p className="my-0 fs-10">Posted by: {x.author}</p>
							<p className="my-0 fs-10">Last Modified: {date}</p>
							</div>
						</div>
					</div>
				)}
			)
			setRender(render+1)
			console.log(forumCards)
			})
		}, [])
	return (
		<Col xs={5}>
			<div>
				<div><h5>{name}s' Forum</h5></div>
			</div>
			<div>
				{(forumCards.length > 0) ? forumCards : <></>}
			</div>
		</Col>
	)
}