import React from 'react';
import { Row, Button, Card } from 'react-bootstrap';
import {Link} from 'react-router-dom';

export default function ReviewHome() {
	return(
	<div className="p-3">
		<Link to="/review/Conversion1">
			<Card>
				<Card.Header>15-item Timed Quiz</Card.Header>
				<Card.Body>
					<Card.Title>Conversion of Units</Card.Title>
					<hr />
					<Card.Text>Hone your skills in converting units involving LENGTH, TIME, VOLUME, and MASS.</Card.Text>
				</Card.Body>
			</Card>
		</Link>
	</div>
	)
}	