import React, { useEffect } from 'react';
import {Navbar, Nav, Row, Col, Button} from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom';
import stud1 from "../pictures/student2.jpg";
import logo from "../pictures/logo_black.png";
import { scroller } from 'react-scroll';
import CredCheck from '../components/Commons/CredentialsCheck.js';
export default function Home() {
	let navi = useNavigate();
	let cred = CredCheck();
	let scrollToSection = () => {
	    scroller.scrollTo("homeDiv", {
	    });
 	};
 	
 	useEffect(() => {
 		scrollToSection();
 	})
 	
 	function goToLogin() {
 		navi("/login");
 	}
	
	return(
		(cred) ? <LoggedIn /> : <LoggedOut />
	);

	function handleButton1(e) {
		navi("/review");
	}

	function LoggedIn() {
		return(
			<div className="container-fluid d-flex m-lg-0 px-2 py-4 p-lg-5 homeDiv justify-content-center">
					<Row className="d-flex justify-content-end w-75 text-justify">
						<h1>Welcome!</h1>
						<hr/>
						<h5>Note from the author</h5>
						<p className="">This site is currently deviating from its original goal and I shall focus on creating a temporary site for my students who wish to take college entrance exams.
						The other links aren't working yet. A lot of work is still in progress regarding backedn operations such as forums and chats, which are on hold due to my day job as a teacher.</p>
						<p>If you are a reviewee, go to your account first. Click the button below.</p>
						<Button onClick={handleButton1}>Click here</Button>
					</Row>	
					
			</div>
		)
	}

	function LoggedOut() { //when user is not logged in }
		return(
			<div className="container-fluid m-0 p-0 mt-0 homeDiv">
				<Row className="p-0 m-0 ">
					<Col className="col-lg-6 d-flex flex-column px-5 py-5">
						<img src={logo} alt="" className="w-100" />
						<hr className=" "  />
						<h1 className="Inter m-5 text-center">Connect teaching ideas with reality.</h1>

						<Button className="m-5 bg-1 button no-border" onClick={goToLogin}>Start Now</Button>
					</Col>
					<Col className="col-lg-6 p-0 m-0 d-none d-md-block">
						<img src={stud1} alt="" id="homepic1" />
					</Col>
				</Row>
			</div>
		)
	}
}