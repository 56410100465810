import React, { useState } from 'react';
import { Routes, Route, useLocation} from 'react-router-dom';
import Home from "./pages/Home.js";
import Chemistry from "./pages/Chemistry.js";
import Biology from "./pages/Biology.js";
import Physics from "./pages/Physics.js";
import Admin from "./pages/Admin.js";
import User from "./pages/User.js";
import Login from "./pages/Login.js";
import Logout from "./pages/Logout.js";
import Register from "./pages/Register.js";
import Chat from "./pages/Chat.js";
import ForumHome from "./pages/ForumHome.js";
import AppNavbar from "./components/AppNav/AppNavbar";
import ReviewHome from "./pages/ReviewHome.js";
import Quiz from "./pages/Quiz.js";
import Page404 from "./pages/Page404.js";
import MyAccount from "./pages/MyAccount.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import { UserContextProvider } from './contexts/UserContext.js';

function App() {
  const [user, setUser] = useState({"token": localStorage.getItem("token"), "email": localStorage.getItem("email"), "isAdmin": localStorage.getItem("isAdmin"), "fName": localStorage.getItem("fName"), "mName": localStorage.getItem("mName"), "lName": localStorage.getItem("lName"), "position": localStorage.getItem("position"), "expiry": localStorage.getItem("expiry")});
  

  //const io = require("socket.io-client")
  //const socket = io("http://localhost:3001/");     Reminder for future connections
  console.log(typeof user)
  console.log(user)
  return (
    <div className="fullWindowWidth">
      <UserContextProvider value={{user, setUser}}>
      {<AppNavbar />}
      <Routes>
        <Route path="/"       element={ <Home /> } />
        <Route path="/chem"   element={ <Chemistry /> } />
        <Route path="/bio"    element={ <Biology   /> } />
        <Route path="/phys"   element={ <Physics   /> } />
        <Route path="/admin"  element={ <Admin     /> } />
        <Route path="/user"   element={ <User      /> } />
        <Route path="/login"  element={ <Login     /> } />
        <Route path="/logout" element={ <Logout    /> } />
        <Route path="/register" element={<Register /> } />
        <Route path="/chat"   element={ <Chat      /> } />
        <Route path="/forum"  element={ <ForumHome /> } />
        <Route path="/review" element={ <ReviewHome/> } />
        <Route path="/review/:quiz" element={ <Quiz/> } />
        <Route path="/MyAccount" element={ <MyAccount /> } />
        <Route path="*" element={ <Page404 /> } />
       </Routes>

      </UserContextProvider>
    </div>
  );
}

export default App;
