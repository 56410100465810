import { useNavigate } from 'react-router-dom';
import React from 'react';

export default function CredCheck() {
	const navi = useNavigate();
	if (localStorage.getItem("token")) {
		if (new Date(localStorage.getItem("expiry")) < new Date() || localStorage.getItem("email") == undefined) {
			navi("/logout");
			return false;
		}
		return true;
	}
	return false;
}