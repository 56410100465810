import React, {useEffect, useState, useContext} from 'react';
import pic1 from '../pictures/student4.jpg'
import {scroller} from 'react-scroll';
import { Form, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import UserContext from '../contexts/UserContext.js';


export default function Login() {
	let [email, setEmail] = useState("");
	let [pass, setPass] = useState("");
	const navi = useNavigate();

	const { setUser } = useContext(UserContext);
	let buttonShow = (email.includes("@") && email.length > 5 && pass.length > 7)
 	useEffect(() => {
 		scrollToSection();
 		if (localStorage.getItem("token") && new Date(localStorage.getItem("expiry")) > new Date())
		navi("/")
 	})

	return(
		<div className="loginDiv d-flex p-0 m-0 fullWindowWidth">
			<img src={pic1} alt="" id="loginPic" className="d-none d-lg-block p-0 m-0"/>
			<div id="loginCard" className=" d-flex flex-column  container-fluid color-2 border-bottom border-right me-lg-5 pe-lg-5 ms-lg-4 pb-lg-5 align-self-center">
				<h1 className="p-0 ps-3 m-3 mb-4 text-res1 varela color-1">Login</h1>
				
				<Form className="ms-3 ps-3 mb-5 color-1" autoComplete="on">
					<Form.Group className="mb-3">
						<Form.Control type="email" placeholder="Email: " onChange={e => setEmail(e.target.value)} onKeyUp={(e) => enterHandler(e)} >
						</Form.Control>	
					</Form.Group>	

					<Form.Group className="mt-3"	>
						<Form.Control type="password" placeholder="Password: " onChange={e => setPass(e.target.value)} onKeyUp={(e) => enterHandler(e)} >
						</Form.Control>
					</Form.Group>	

					<Form.Group className="mb-3 mt-2">
						<Link to="/register" className="color-1" >Don't have an account yet? Click here!</Link>
					</Form.Group>

					{
						(buttonShow) ?
						<Button className="bg-2" onClick={loginHandler}>Log In</Button> :
						<Button className="bg-2" disabled>Log In</Button>
					}


				</Form>
			</div>
		</div>
	);
	function loginHandler() {
		sendLoginData(email, pass, setEmail, setPass, navi, setUser)

	}
	function enterHandler(e) {
		console.log(e.keyCode)
		if (buttonShow && e.keyCode === 13)
			loginHandler()

	}

}

function scrollToSection() {
    scroller.scrollTo("loginDiv", {
    });
};



function sendLoginData(email, pass, setEmail, setPass, navi, setUser) {
	fetch("https://arcane-taiga-71197.herokuapp.com/users/login", {
		"method": "POST",
		"headers": {"Content-type" : "application/json"},
		"body": JSON.stringify({
			email: email,
			password: pass
		})
	}).then(response => response.json()).then(result => {
		if (result.message) {
			localStorage.setItem("token", JSON.stringify(result.token).replace(/"/g, ""));
			localStorage.setItem("fName", JSON.stringify(result.fName).replace(/"/g, ""));
			localStorage.setItem("mName", JSON.stringify(result.mName).replace(/"/g, ""));
			localStorage.setItem("lName", JSON.stringify(result.lName).replace(/"/g, ""));
			localStorage.setItem("email", JSON.stringify(result.email).replace(/"/g, ""));
			localStorage.setItem("isAdmin", JSON.stringify(result.isAdmin).replace(/"/g, ""));
			localStorage.setItem("position", JSON.stringify(result.position).replace(/"/g, ""));
			localStorage.setItem("expiry", JSON.stringify(result.expiry).replace(/"/g, ""));
			localStorage.setItem("id", JSON.stringify(result.id).replace(/"/g, ""));
			setEmail("")
			setPass("")
			Swal.fire({
				icon: "success",
				title: "Successfully logged in"
			})
			setUser({"token": localStorage.getItem("token"), "email": localStorage.getItem("email"), "isAdmin": localStorage.getItem("isAdmin"), "fName": localStorage.getItem("fName"), "mName": localStorage.getItem("mName"), "lName": localStorage.getItem("lName"), "position": localStorage.getItem("position"), "expiry": localStorage.getItem("expiry")})
			navi("/")

		}
		else{
			Swal.fire({
				icon: "error",
				title: `"${result.error}"`
			})
		}
	})
}