import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import stat2 from '../pictures/stat2.jpg'
export default function MyAccount() {
	let user = localStorage.getItem("fName") + " " + localStorage.getItem("mName") + " " + localStorage.getItem("lName");
	return(
		<div>
			<div className="d-flex flex-column bg-9 p-3 height-50 align-items-center justify-content-center align-items-center ">
				<div className="no-pic bg-9 mb-3 d-flex justify-content-center">
					<p className="varela-round">{user.charAt(0)}</p>
				</div>
				<h5 className="text-white">{user}</h5>
			</div>
			<div className="py-3 bg-5">
				

				<div style={{backgroundImage: `url(${stat2})`, width: "100%", height: "100px", backgroundSize: "cover" }} className="d-flex align-items-end">
					<div className="darkbg-white-text py-1 px-3">
						<h4>Quiz Statistics</h4>
					</div>
				</div>
			</div>
		</div>
	)
}